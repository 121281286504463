<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <defs>
      <filter id="1jq9uz968a" color-interpolation-filters="auto">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 0.949020 0 0 0 0 0.576471 0 0 0 0 0.196078 0 0 0 1.000000 0"
        />
      </filter>
    </defs>
    <g
      fill="none"
      fill-rule="evenodd"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <g
        filter="url(#1jq9uz968a)"
        transform="translate(-985.000000, -501.000000)"
      >
        <g>
          <g stroke="#323232" stroke-width="1.275">
            <path
              d="M3.333 1.25H1.667C.746 1.25 0 1.996 0 2.917v11.666c0 .921.746 1.667 1.667 1.667h10c.92 0 1.666-.746 1.666-1.667v-.833"
              transform="translate(985.000000, 501.000000) translate(2.500000, 1.666667)"
            />
            <path
              d="M8.333 12.917l2.625-.325c.184-.022.356-.106.488-.237l3.859-3.86c.704-.703.704-1.845 0-2.55-.704-.704-1.846-.704-2.55 0l-3.8 3.8c-.128.128-.21.293-.237.472l-.385 2.7zM8.75 2.5H4.583c-.69 0-1.25-.56-1.25-1.25h0c0-.69.56-1.25 1.25-1.25H8.75C9.44 0 10 .56 10 1.25h0c0 .69-.56 1.25-1.25 1.25zM3.333 5.417L8.333 5.417M3.333 8.75L6.667 8.75M10 1.25h1.667c.92 0 1.666.746 1.666 1.667"
              transform="translate(985.000000, 501.000000) translate(2.500000, 1.666667)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconEdit",
};
</script>

<style></style>
